






import { Component, Vue } from 'vue-property-decorator'

import InstructionForm from '@/partials/forms/Medical/InstructionForm.vue'

@Component({
  components: {
    InstructionForm
  }
})
export default class MedicalInstructionCreate extends Vue {

}
